<template>
  <div class="card" style="border: none" :id="isPrint ? 'print' : ''">
    <div class="card-body" :style="isPrint ? `height: ${height};` : ''">
      <div class="row justify-content-between align-items-center">
        <div class="col-8">
          <table>
            <div style="font-size: 14pt"><strong>Invoice</strong></div>
            <tr>
              <td style="width: 100px">Nomor</td>
              <td style="width: 9px">:</td>
              <td>{{ data.id }}</td>
            </tr>
            <tr>
              <td>Tanggal</td>
              <td style="width: 9px">:</td>
              <td>{{ data.display_created_at }}</td>
            </tr>
            <tr>
              <td>Nama Pasien</td>
              <td style="width: 9px">:</td>
              <td>{{ data.patient_name }}</td>
            </tr>
            <tr>
              <td>Nomor HP</td>
              <td style="width: 9px">:</td>
              <td>{{ data.patient_mobile_phone }}</td>
            </tr>
            <tr>
              <td>Dokter</td>
              <td style="width: 9px">:</td>
              <td>{{ data.doctor_name }}</td>
            </tr>
          </table>
        </div>
        <div class="col">
          <img
            src="/images/hayandra.jpeg"
            height="60"
            style="margin-left: -10px"
          />
          <div class="font-weight-light" style="font-size: 10.7px">
            {{ setting.address }}
          </div>
          <div class="font-weight-light" style="font-size: 10.7px">
            {{ setting.emergency }}
          </div>
        </div>
      </div>
      <div style="min-height: 285px">
        <b-table striped hover small :items="items" :fields="fields">
          <template #table-colgroup="scope">
            <col v-for="field in scope.fields" :key="field.key" />
          </template>
          <template #cell(price)="data">
            {{
              "Rp " +
              parseInt(
                parseInt(data.item.price) -
                  parseInt(data.item.promo_amount) +
                  data.item.discount_amount
              ).toLocaleString("id-ID")
            }}
          </template>
          <template #cell(quantity)="data">
            {{ data.item.ref_table == 3 ? data.item.quantity : "" }}
          </template>
          <template #cell(discount)="data">
            <span v-if="data.item.discount_type == 0">
              Rp
              {{ parseInt(data.item.discount_amount).toLocaleString("id-ID") }}
            </span>
            <span v-if="data.item.discount_type == 1">
              Rp
              {{
                parseInt(data.item.discount_percentage).toLocaleString("id-ID")
              }}
            </span>
          </template>
          <template #cell(subtotal)="data">
            Rp {{ parseInt(data.item.total).toLocaleString("id-ID") }}
          </template>
        </b-table>
      </div>
      <div class="d-flex justify-content-between align-items-center">
        <div class="mr-2">
          <!-- Notes -->
          <div class="form-group" v-if="renderNotes">
            <label for="notes">Catatan:</label>
            <textarea
              name=""
              id="notes"
              cols="30"
              rows="3"
              class="form-control"
              style="resize: none"
              v-model="data.notes"
              readonly
            ></textarea>
          </div>
        </div>
        <table v-if="showSummary" style="width: 300px">
          <tr v-if="data.total_discount_item != 0 && typeof data.total_discount_item != 'undefined' ">
            <td>Total Diskon Item</td>
            <td class="font-weight-bold" style="width: 25px">Rp</td>
            <td class="text-right">
              <span class="font-weight-bold">{{
                parseInt(data.total_discount_item ).toLocaleString("id-ID")
              }}</span>
            </td>
          </tr>
          <tr>
            <td style="width: 150px">Subtotal</td>
            <td class="font-weight-bold" style="width: 25px">Rp</td>
            <td class="text-right">
              <span class="font-weight-bold">{{
                parseInt(data.total_amount).toLocaleString("id-ID")
              }}</span>
            </td>
          </tr>
          <tr>
            <td>Diskon Transaksi</td>
            <td class="font-weight-bold" style="width: 25px">Rp</td>
            <td class="text-right">
              <span class="font-weight-bold">{{
                parseInt(data.discount).toLocaleString("id-ID")
              }}</span>
            </td>
          </tr>
          <tr>
            <td>Biaya Tambahan</td>
            <td class="font-weight-bold" style="width: 25px">Rp</td>
            <td class="text-right">
              <span class="font-weight-bold">{{
                parseInt(data.surcharge).toLocaleString("id-ID")
              }}</span>
            </td>
          </tr>
          <tr v-if="data.coupon_amount">
            <td>Kupon</td>
            <td class="font-weight-bold" style="width: 25px">Rp</td>
            <td class="text-right">
              <span class="font-weight-bold">
                {{ parseInt(data.coupon_amount).toLocaleString("id-ID") }}
              </span>
            </td>
          </tr>
          <tr>
            <td>Total</td>
            <td class="font-weight-bold" style="width: 25px">Rp</td>
            <td class="text-right">
              <span class="font-weight-bold">{{
                parseInt(data.final_amount).toLocaleString("id-ID")
              }}</span>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <div class="d-flex justify-content-center p-0 mt-n4">
      <div>
        <hr class="special-hr" style="margin: 2px" />
        <div>{{ currentUserPersonalInfo.name }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    data: Object,
    height: String,
    isPrint: Boolean,
    setting: Object,
    items: Array,
    renderNotes: Boolean,
    showSummary: Boolean,
  },

  computed: {
    ...mapGetters(["currentUserPersonalInfo"]),
  },

  data() {
    return {
      // Table Head
      fields: [
        {
          key: "number",
          label: "#",
          sortable: true,
        },
        {
          key: "name",
          label: "Keterangan",
        },
        {
          key: "quantity",
          label: "Jumlah",
          thStyle: { width: "5%" },
        },
        {
          key: "discount",
          label: "Diskon",
          thClass: "text-right",
          tdClass: "text-right",
        },
        {
          key: "price",
          label: "Harga",
          thClass: "text-right",
          tdClass: "text-right",
        },
        {
          key: "subtotal",
          label: "Subtotal",
          thClass: "text-right",
          tdClass: "text-right",
        },
      ],
    };
  },
};
</script>

<style scoped>
.special-hr {
  border-color: #000000;
  border-style: solid;
  border-width: 2px 0 0;
  clear: both;
  margin: 0 0 0px;
  height: 0;
}
</style>